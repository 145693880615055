import { useAuthApi } from '../../../../../apiHooks';
import { ILoginPublishingForm } from './LoginPublishing.interfaces';
import { useForm } from '../../../../../hooks/useForm';
import {
  initialLoginPublishingForm,
  initialLoginPublishingFormProd,
  messagesLoginPublishingQueue,
  validationLoginPublishingSchema
} from './LoginPublishing.helper';
import { useModal } from '../../../../shared/modal/useModal';
import { useAuthStore, usePoiStore } from '../../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { ValidationError } from '../../../../../utils';

export const useLoginPublishing = () => {
  const { loginUser } = useAuthApi();
  const authStore = useAuthStore();
  const { handleClose, handleOpen, open } = useModal();
  const navigate = useNavigate();
  const poiStore = usePoiStore();
  const { error, isGlobalError } = poiStore;
  const onSubmit = async (values: ILoginPublishingForm) => {
    const isLogin = await loginUser(values);
    if (isLogin && authStore.isLoggedIn) {
      navigate('/');
    }
  };

  const {
    formik
  } = useForm<ILoginPublishingForm>(process.env.REACT_APP_ENV === 'PROD' ? initialLoginPublishingFormProd : initialLoginPublishingForm, validationLoginPublishingSchema, onSubmit, messagesLoginPublishingQueue);

  const errorMessageUser = isGlobalError && error === "Invalid credentials." ? ValidationError.LOGIN_NOT_CORRECT : error;

  return {
    formik,
    handleClose, handleOpen, open,
    errorMessageUser
  };
};