import { Button, Grid, Typography } from '@mui/material';
import { loginPublishingFormConfig } from './LoginPublishing.helper';
import { LoginLayout } from '../loginLayout/LoginLayout';
import { GeneralLink, generateInputs } from '../../../../../utils';
import { observer } from 'mobx-react-lite';
import { useLoginPublishing } from './useLoginPublishing.vm';
import { ResetPasswordModal } from './modal/ResetPasswordModal';
import { Header } from '../loginLayout/LoginLayout.styled';

const LoginPublishing = observer(() => {
  const { formik, handleOpen, open, handleClose, errorMessageUser } = useLoginPublishing();

  return (
    <LoginLayout header={<Header>Zaloguj się w serwisie</Header>} extraLink={{text: 'Nie masz konta? Przejdź do serwisu podając swój numer PWZ  ', link: GeneralLink.LOGIN_USER }}>
      <>
        {
          generateInputs(loginPublishingFormConfig, formik)
        }
        {errorMessageUser && <Typography variant={'caption'} color={'error'}>{errorMessageUser}</Typography>}
        <Grid container gap={2} flexWrap={'nowrap'} display={'flex'} flexDirection={'row'}>
          <Grid item xs={6}>
            <Button fullWidth={true} onClick={() => formik.handleSubmit()} variant='contained'>Zaloguj się</Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth={true} onClick={() => handleOpen('add')} variant='outlined'>Reset hasła</Button>
          </Grid>

        </Grid>
      </>
      {open && <ResetPasswordModal open={open} handleClose={handleClose} />}
    </LoginLayout>
  );
});

export default LoginPublishing;