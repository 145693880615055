import { ModalRoot } from '../../../../../shared/modal/ModalRoot';
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Input } from '../../../../../shared/ui/form/input/Input';
import { useResetPasswordModal } from './useResetPasswordModal.vm';

interface IProps {
  open: boolean,
  handleClose: () => void;
}

export const ResetPasswordModal: React.FC<IProps> = ({ open, handleClose }) => {

  const { formik, error, onChangeCustom} = useResetPasswordModal(handleClose);

  return (
    <ModalRoot open={open} handleClose={handleClose} title={'Reset hasła'}>
      <Grid container pb={2} pl={3} pr={3} flexDirection={'column'} width={'700px'}>
        <Grid item xs={12}>
          <Typography variant={'caption'}>Podaj podany przy rejestracji adres email, na który zostanie wysłana wiadomość
            z linkiem umożliwiającym zmianę hasła.</Typography>
        </Grid>
        <Grid item xs={12} mt={2} mb={1}>
          <Input
            onBlur={formik.handleBlur}
            autoComplete={'off'}
            type={'text'}
            fullWidth={true}
            required={true}
            name={'email'}
            label={'Adres email podany przy rejestracji'}
            placeholder={'Adres email podany przy rejestracji'}
            onChange={onChangeCustom}
            errorMessage={error ? String(error) : formik.errors.email}
            error={formik.touched.email && (Boolean(error) || Boolean(formik.errors.email))}
            value={formik.values.email || ''} />
        </Grid>
        <Grid container justifyContent={'flex-end'} mt={2}>
          <Grid item>
            <Button disabled={false} variant='outlined' color={'primary'}
                    onClick={handleClose}>Anuluj</Button>
          </Grid>
          <Grid item ml={2}>
            <Button disabled={false} variant='contained' color={'primary'}
                    onClick={() => formik.handleSubmit()}>Wyślij</Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalRoot>
  );
};