import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import * as yup from 'yup';
import { ValidationError } from '../../../../../../utils';
import { useAuthApi } from '../../../../../../apiHooks';
import { usePoiStore } from '../../../../../../store/hooks';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(ValidationError.EMAIL_NOT_CORRECT)
    .required(ValidationError.EMAIL_NOT_CORRECT)

});
export const useResetPasswordModal = (handleClose: () => void) => {
  const { resetPassword } = useAuthApi(handleClose);
  const poiStore = usePoiStore()
  const { errorCode} = poiStore;
  const [error, setError] = useState<false | string>(false)
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await resetPassword(values.email);
    }
  });

  useEffect(() => {
    if(errorCode){
      setError(errorCode === 401 ? ValidationError.EMAIL_NOT_CONFIRMED:  ValidationError.EMAIL_NOT_CORRECT)
    }

  }, [errorCode]) 

  const onChangeCustom = (e: React.ChangeEvent<any>) => {
    formik.handleChange(e)
    poiStore.clearError()
    setError(false)
  }

  return {
    formik,
    error,
    onChangeCustom
  };
};