import { useCallback } from 'react';
import { useAuthStore, useCaseStore, usePoiStore, useProgressBarStore, useQAPageStore } from '../store/hooks';
import { useApiCall } from './useApiCall';
import { ILoginPublishingForm } from '../components/pages/auth/login/loginPublishing/LoginPublishing.interfaces';
import { ILoginUserForm } from '../components/pages/auth/login/loginUser/LoginUser.interfaces';
import { IAuthResponse } from '../interfaces/api/auth';
import { useCaseSListStore } from '../store/hooks/useCasesListStore';


export const useAuthApi = (handleClose?: () => void) => {
  const { api } = useApiCall();
  const authStore = useAuthStore();
  const poiStore = usePoiStore();
  const qaPageStore = useQAPageStore();
  const caseStore = useCaseStore();
  const progressBarStore = useProgressBarStore();
  const casesStore = useCaseStore();
  const caseSListStore = useCaseSListStore();

  const loginUser = useCallback(
    async (loginBody: ILoginPublishingForm) => {
      const res: IAuthResponse = await api.apiInstance.post('login', loginBody);
      if (res) {
        authStore.appLogin(res);
        poiStore.clearError();
        return true;
      }

    },
    [api.apiInstance, authStore, poiStore]
  );

  const loginUserPWZ = useCallback(
    async (loginBody: ILoginUserForm) => {
      const res: IAuthResponse = await api.apiInstance.post('login/pwz', loginBody);
      if (res) {
        authStore.appLogin(res);
        return true;
      } else {
        return false;
      }
    },
    [api.apiInstance, authStore]
  );


  const checkAuth = useCallback(
    async () => {
      const res = await api.apiInstance.get('is-auth');

      if (!res) {
        authStore.setIsSessionExpired(true);
      } else {
        authStore.appLogin(res);
      }
      authStore.userLoad();
    },
    [api.apiInstance, authStore]
  );

  const serverLogoutUser = useCallback(async () => {
    const res = await api.apiInstance.post('logout', {});
    if (res) {
      authStore.appLogout();

      //clear all stores
      qaPageStore.clearStore();
      caseStore.clearStore();
      progressBarStore.clearStore();
      casesStore.clearStore();
      caseSListStore.clearStore();
    }

    return true;
  }, [api.apiInstance, caseStore, authStore, qaPageStore, progressBarStore, casesStore, caseSListStore]);

  const getEmailByToken = useCallback(async (token: string) => {
      const res = await api.apiInstance.get(`users/token/${token}`, true);
      if (res) {
        authStore.setUserEmailBytoken(res.email);
      }
    },
    [api.apiInstance, authStore]
  );

  const registerUser = useCallback(async (token: string, data: any) => {
      const res = await api.apiInstance.post(`users/register-publishing/${token}`, data, true);
      if (res) {
        return !!res;
      }
    },
    [api.apiInstance]
  );

  const resetPassword = useCallback(async (email: string) => {
      const res = await api.apiInstance.post('users/send-mail-reset-password', { email }, true);
      
      if (res) {
        handleClose && handleClose();
        return true
      }
      else{
        return false
      }
    },
    [api.apiInstance, handleClose]
  );

  const changePassword = useCallback(async (password: string, token: string) => {
      const res = await api.apiInstance.post(`users/reset-password/${token}`, { password }, true);
      if (res) {
        return !!res;
      }
    },
    [api.apiInstance]
  );

  return {
    checkAuth,
    loginUserPWZ,
    loginUser,
    serverLogoutUser,
    getEmailByToken,
    registerUser,
    resetPassword,
    changePassword
  };
};
