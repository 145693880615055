export class ValidationError {
  static PASSWORD_NOT_CORRECT = 'Hasło musi zawierać: jedną dużą i małą literę, cyfrę, znak specjalny i składać się z conajmniej 8 znaków.';
  static PASSWORDS_NOT_MATCH = 'Nowe hasło i powtórzone hasło różnią się.';
  static FIELDS_REQUIRED = 'Pole oznaczone gwiazdką są wymagane.';
  static FIELD_REQUIRED = 'Pole jest wymagane.';
  static EMAIL_NOT_CORRECT = 'Nieprawidłowy adres email.';
  static EMAIL_NOT_CONFIRMED = 'Podany adres email nie jest potwierdzony. Potwierdź konto klikając w link aktywacyjny przysłany na Twój adres email.';
  static PWZ_NOT_CORRECT = 'Nieprawidłowy numer PWZ.';
  static LOGIN_NOT_CORRECT = 'Dane logowania się nie zgadzają. Sprawdź swoje dane i upewnij się, że nie masz włączonego CAPS LOCK.'
  static LOGIN_NOT_FINISHED_REGISTRATION = 'Aby móc się zalogować dokończ rejestrację. Rejestrację dokończysz klikając w link aktywacyjny wysłany na Twój adres email.';
}